<template>
    <div>
        <div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            {{itemId ? moduleName + " bewerken" : moduleName + " toevoegen"}}
                        </h5>
                        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div :class="{ 'd-none': !loading }">
                            <i class="fas fa-spinner fa-spin"></i> Even wachten...
                        </div>
                        <div :class="{ 'd-none': loading }"> 
                            <slot></slot>
                        </div>
                    </div>
                    <div class="modal-footer">
                            
                        <button type="button" class="btn btn-success" @click.prevent="save">Opslaan</button>
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{moduleName}} verwijderen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Weet je zeker dat je <b>{{itemName}}</b> wilt verwijderen?
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Nee</button>
                        <button type="button" class="btn btn-danger" @click.prevent="del">Ja</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormPopup',
        emits: ['save', 'del'],
        props: ['moduleName', 'itemId', 'itemName', 'loading'],
        methods: {
            save() {
                this.$emit("save");
            },
            del() {
                this.$emit("del");
            }
        }
    }

</script>
